import React from 'react'

import SEO from '../components/seo'
import '../css/404.css'

const NotFound = () => (
  <React.Fragment>
    <SEO
      title="404 Not Found"
    />
    <div id="error-page" className="error">
      <div>
        <h1>404</h1>
        <div className="error-detail">
          <h2>
            This page could not be found.
          </h2>
        </div>
      </div>
    </div>
  </React.Fragment>
)

export default NotFound
